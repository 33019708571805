import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faBars,
    faUser,
    faKeyboard,
    faSliders,
    faVolumeHigh,
    faTrashCan,
    faRightFromBracket,
    faMobileScreen,
    faFloppyDisk,
    faDatabase,
    faCheck,
    faClock,
    faServer,
    faList,
    faCodeBranch,
    faComment,
    faPaperPlane,
    faCode,
    faArrowPointer,
    faPaintBrush,
    faMusic,
    faAtom,
    faPaw,
} from "@fortawesome/free-solid-svg-icons";
import { faChrome, faGithub, faReact } from "@fortawesome/free-brands-svg-icons";

library.add(
    faBars,
    faGithub,
    faUser,
    faKeyboard,
    faReact,
    faSliders,
    faVolumeHigh,
    faTrashCan,
    faRightFromBracket,
    faMobileScreen,
    faFloppyDisk,
    faDatabase,
    faCheck,
    faClock,
    faServer,
    faList,
    faCodeBranch,
    faComment,
    faPaperPlane,
    faCode,
    faArrowPointer,
    faPaintBrush,
    faMusic,
    faAtom,
    faChrome,
    faPaw,
);
