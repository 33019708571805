import React from "react";
import Header from "../components/Header";

const NoMatchPage = () => {
    return (
        <div>
            <Header />
            NoMatchPage
            <p>no match!!</p>
        </div>
    );
};

export default NoMatchPage;
